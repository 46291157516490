import React, { useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '~/components/layout'
import Card from '~/components/styled/card'
import SEOEn from '~/components/seoEn'

const AuctionsPage = ({ data: { allStrapiVentes, allStrapiPageVentes } }) => {
  const salesData = allStrapiVentes.nodes
  const { background } = allStrapiPageVentes.nodes[0]
  const seo = {
    title: 'Sales',
    metaDescription:
      'Discover our next auctions in Besançon, Belfort, Vesoul and Colmar.',
  }

  const [activePlace, setActivePlace] = useState('all')

  return (
    <Layout isEn>
      <SEOEn seo={seo} />
      <div
        className="custom-bg"
        style={{ backgroundImage: `url(${background.url})` }}
      ></div>
      <div>
        <div className="grid grid-cols-12 container">
          <div className="col-start-1 col-end-13 mb-10 push-content">
            {salesData.length ? (
              <>
                <div className="largeMobile:flex largeMobile:w-max mx-auto mt-4 mb-6 p-1 bg-white">
                  <div
                    onClick={() => setActivePlace('all')}
                    className={`${
                      activePlace === 'all' ? 'bg-gray-100' : 'bg-white'
                    } p-2 cursor-pointer`}
                  >
                    <label
                      className={`${
                        activePlace === 'all' ? 'font-bold' : ''
                      } uppercase cursor-pointer tracking-wider`}
                      htmlFor="all"
                    >
                      All places
                    </label>
                    <input id="all" className="hidden" type="radio" />
                  </div>
                  <div
                    onClick={() => setActivePlace('besançon')}
                    className={`${
                      activePlace === 'besançon' ? 'bg-gray-100' : 'bg-white'
                    } p-2 cursor-pointer`}
                  >
                    <label
                      className={`${
                        activePlace === 'besançon' ? 'font-bold' : ''
                      } uppercase cursor-pointer tracking-wider`}
                      htmlFor="besancon"
                    >
                      Besançon
                    </label>
                    <input id="besancon" className="hidden" type="radio" />
                  </div>
                  <div
                    onClick={() => setActivePlace('belfort')}
                    className={`${
                      activePlace === 'belfort' ? 'bg-gray-100' : 'bg-white'
                    } p-2 cursor-pointer`}
                  >
                    <label
                      className={`${
                        activePlace === 'belfort' ? 'font-bold' : ''
                      } uppercase cursor-pointer tracking-wider`}
                      htmlFor="belfort"
                    >
                      Belfort
                    </label>
                    <input id="belfort" className="hidden" type="radio" />
                  </div>
                </div>
                <h1 className="mb-2 uppercase text-xl font-bold text-white">
                  Next auctions
                </h1>
                <div>
                  {salesData
                    .filter(
                      el =>
                        el.place.toLowerCase() === activePlace ||
                        activePlace === 'all'
                    )
                    .map((sale, id) => (
                      <Card key={id} locale="en">
                        {sale}
                      </Card>
                    ))}
                </div>
              </>
            ) : (
              <div>No upcoming auction</div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query salesQueryEn {
    allStrapiVentes(sort: { order: ASC, fields: complete_date }) {
      nodes {
        title
        type
        url
        place
        date
        hour
        photos
      }
    }
    allStrapiPageVentes {
      nodes {
        background {
          url
        }
      }
    }
  }
`

export default AuctionsPage
